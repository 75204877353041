<template>
  <div class="torna-indietro mt-4 mb-4 d-flex justify-content-end">

    <!--<a href="" onclick="event.preventDefault(); window.scrollTo(0,0)">
      <BtnBack text="Torna su" />
    </a>-->

    <div @click="mixinGoBack()">
      <BtnBack :text="'Torna indietro'" />
    </div>

  </div>
</template>

<script>
  import BtnBack from '@/components/atoms/btn-back'
  export default {
    name: 'torna-indietro',
    components: {
      BtnBack,
    }
  }
</script>