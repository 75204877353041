var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comeFunziona" }, [
    _c("h2", { staticClass: "common-title" }, [
      _vm._v("Come funziona Salabam"),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { sm: "12", md: "4" } }, [
              _c(
                "div",
                { staticClass: "step" },
                [
                  _c("span", [
                    _vm._v("Acquista"),
                    _c("br"),
                    _vm._v("il voucher"),
                  ]),
                  _c("SvgCartLarge"),
                ],
                1
              ),
            ]),
            _c("b-col", { attrs: { sm: "12", md: "4" } }, [
              _c(
                "div",
                { staticClass: "step" },
                [
                  _c("span", [
                    _vm._v("Ricevilo"),
                    _c("br"),
                    _vm._v("via mail"),
                  ]),
                  _c("SvgMailLarge"),
                ],
                1
              ),
            ]),
            _c("b-col", { attrs: { sm: "12", md: "4" } }, [
              _c(
                "div",
                { staticClass: "step" },
                [
                  _c("span", [
                    _vm._v("Prenota"),
                    _c("br"),
                    _vm._v("il viaggio"),
                  ]),
                  _c("SvgScreenLarge"),
                ],
                1
              ),
            ]),
            _c("b-col", { attrs: { sm: "12", md: "4" } }, [
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "\n                  Riceverai l'email con il link per prenotare pochi istanti dopo l'acquisto.\n                "
                ),
              ]),
            ]),
            _c("b-col", { attrs: { sm: "12", md: "4" } }, [
              _c("p", { staticClass: "text-center" }, [
                !_vm.isPromo
                  ? _c("span", [_c("CopyScadenza")], 1)
                  : _c("span", [_vm._v(_vm._s(_vm.promoText) + ".")]),
              ]),
            ]),
            _c("b-col", { attrs: { sm: "12", md: "4" } }, [
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "\n                  Tutte le disponibilità sono reali e prenotabili con pochi click senza necessità di telefonate all'hotel o conferme ulteriori.\n                "
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }