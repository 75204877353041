var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "150",
        height: "150",
        viewBox: "0 0 150 150",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "75",
          cy: "75",
          r: "74",
          fill: "white",
          stroke: "#F2F2F2",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          d: "M112.969 32H37.0312C33.1542 32 30 35.1542 30 39.032V88.958C30 90.5112 31.2727 90 32.8259 90C34.3791 90 35.625 85.5532 35.625 84V39.0312C35.625 38.255 36.2557 37.6243 37.0312 37.6243H112.969C113.744 37.6243 114.375 38.255 114.375 39.0312V96.2354C114.375 97.0116 113.744 97.6416 112.969 97.6416H37.0312C36.2557 97.6416 35.625 97.0116 35.625 96.2354V88.958H117C118.553 88.958 116 87.6973 116 86.1441C116 84.5909 117.053 84 115.5 84L32.8259 83.333C32.8209 83.333 32.8167 83.3316 32.8125 83.3316C31.2593 83.3316 30 84.5909 30 86.1441V96.2354C30 100.112 33.1542 103.267 37.0312 103.267H63.1432L58.4913 113.513C57.8494 114.927 58.4759 116.594 59.8898 117.237C60.2667 117.407 60.6619 117.488 61.0507 117.488C62.1202 117.488 63.1425 116.876 63.6136 115.839L69.3223 103.266H80.6798L84.5836 111.863H75.1153C73.5621 111.863 72.3028 113.122 72.3028 114.676C72.3028 116.229 73.5621 117.488 75.1153 117.488H88.9493C89.9048 117.488 90.7957 117.002 91.3132 116.199C91.8307 115.395 91.9052 114.383 91.5101 113.513L86.8582 103.267H112.969C116.846 103.267 120 100.112 120 96.2354V39.0312C120 35.1542 116.846 32 112.969 32Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.4391 58.17L60.4396 58.1696C62.1982 56.5853 64.8883 56.745 66.4577 58.5319L70.1024 62.6862L70.7879 63.4675L71.5422 62.7523L84.8175 50.1635C86.5361 48.5357 89.2317 48.6278 90.8446 50.3654C92.4605 52.1156 92.3701 54.8645 90.6448 56.5009C90.6448 56.5009 90.6448 56.5009 90.6447 56.5009L73.4202 72.8335C73.4201 72.8336 73.42 72.8336 73.42 72.8337C73.4199 72.8338 73.4198 72.8338 73.4197 72.8339C72.6256 73.5858 71.5844 74 70.5063 74C70.4459 74 70.3858 73.9981 70.3046 73.9955C69.1579 73.9387 68.0814 73.4194 67.3167 72.5481C67.3167 72.548 67.3166 72.5479 67.3166 72.5479L60.0808 64.2975L60.0808 64.2975C58.5087 62.5051 58.6717 59.76 60.4391 58.17Z",
          fill: "#E7775A",
          stroke: "white",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }