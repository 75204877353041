<template>
    <div class="comeFunziona">
        <h2 class="common-title">Come funziona Salabam</h2>
        <div class="content">
            <b-row>
                <b-col sm="12" md="4">
                    <div class="step">
                        <span>Acquista<br>il voucher</span>
                        <SvgCartLarge />
                    </div>
                </b-col>
                <b-col sm="12" md="4">
                    <div class="step">
                        <span>Ricevilo<br>via mail</span>
                        <SvgMailLarge />
                    </div>
                </b-col>
                <b-col sm="12" md="4">
                    <div class="step">
                        <span>Prenota<br>il viaggio</span>
                        <SvgScreenLarge />
                    </div>
                </b-col>
                
                <b-col sm="12" md="4">
                    <p class="text-center">
                      Riceverai l'email con il link per prenotare pochi istanti dopo l'acquisto.
                    </p>
                </b-col>
                <b-col sm="12" md="4">
                    <p class="text-center">
                      <span v-if="!isPromo"><CopyScadenza  /></span>
                      <span v-else>{{promoText}}.</span>
                    </p>
                </b-col>
                <b-col sm="12" md="4">
                    <p class="text-center">
                      Tutte le disponibilità sono reali e prenotabili con pochi click senza necessità di telefonate all'hotel o conferme ulteriori.
                    </p>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'
import SvgCartLarge from './../../svg/cart-large'
import SvgMailLarge from './../../svg/mail-large'
import SvgScreenLarge from './../../svg/screen-large'

export default {
    name: 'come-funziona-salabam',
    components:
    {
        CopyScadenza,
        SvgCartLarge,
        SvgMailLarge,
        SvgScreenLarge,
    },
    data(){
      return {
        isPromo: Vue.prototype.$config.guiSettings.vouchersValidity.isPromo,
        promoText: Vue.prototype.$config.guiSettings.vouchersValidity.promoText,
      }
    },
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .comeFunziona
    {
        margin-top:$grid-gutter-width;
        margin-bottom:$grid-gutter-width;
        .common-title
        {
            margin-top:0;
            text-align:center;
            margin-bottom:$grid-gutter-width;
        }
        .content
        {
            //margin-bottom:$grid-gutter-width;
        }
        .step
        {
            flex:1;
            display: flex;
            flex-direction:column;
            text-align:center;
            align-items:center;
            margin-bottom:$grid-gutter-width;
            font-weight:bold;
            font-size: 1rem;
            svg
            {
                width:90%;
                height:auto;
                max-width: 100px;
                margin-top:1.5rem;
            }
        }
    }
</style>