var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "150",
        height: "150",
        viewBox: "0 0 150 150",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "75",
          cy: "75",
          r: "74",
          fill: "white",
          stroke: "#F2F2F2",
          "stroke-width": "2",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.5241 32.077C42.1922 30.8515 41.0798 30 39.81 30H32.8125C31.2593 30 30 31.2593 30 32.8125C30 34.3657 31.2593 35.625 32.8125 35.625H37.6584L47.4607 71.8092C47.7996 73.0622 48.9359 73.887 50.1741 73.887C50.4173 73.887 50.6641 73.8553 50.9116 73.7878C52.41 73.3821 53.2973 71.8373 52.8902 70.3383L42.5241 32.077Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M117.188 88.6962H61.3174C59.8795 88.6962 58.7109 87.5269 58.7109 86.0897C58.7109 84.6525 59.8795 83.4839 61.3174 83.4839H108.496C108.497 83.4839 108.499 83.4846 108.501 83.4846C108.505 83.4846 108.509 83.4825 108.513 83.4825C108.792 83.4811 109.056 83.4277 109.31 83.351C109.361 83.3355 109.409 83.3229 109.458 83.3053C109.706 83.2146 109.938 83.0937 110.148 82.9404C110.186 82.913 110.219 82.8813 110.256 82.8518C110.46 82.6873 110.644 82.5016 110.796 82.2872C110.805 82.2745 110.816 82.2647 110.825 82.252C110.982 82.0214 111.094 81.7612 111.177 81.4877C111.186 81.4589 111.206 81.4371 111.213 81.4076L119.902 49.3338C119.91 49.3064 119.909 49.279 119.915 49.2516C119.937 49.1595 119.949 49.0652 119.962 48.9696C119.975 48.8726 119.988 48.7763 119.991 48.6792C119.992 48.6518 120 48.6258 120 48.5977C120 48.5379 119.985 48.4816 119.982 48.4226C119.976 48.3213 119.969 48.2215 119.952 48.1223C119.936 48.0323 119.913 47.9459 119.889 47.8587C119.865 47.7722 119.841 47.6864 119.81 47.6027C119.776 47.5134 119.735 47.4291 119.692 47.344C119.654 47.2673 119.615 47.1914 119.57 47.1183C119.521 47.0388 119.466 46.965 119.41 46.8919C119.355 46.8216 119.301 46.7527 119.241 46.6873C119.18 46.6212 119.114 46.5614 119.047 46.5016C118.979 46.4412 118.91 46.3821 118.836 46.328C118.761 46.2731 118.682 46.2267 118.602 46.1796C118.526 46.1353 118.451 46.091 118.37 46.0537C118.278 46.0109 118.182 45.9778 118.086 45.9448C118.03 45.9258 117.979 45.8977 117.922 45.8822C117.894 45.8752 117.868 45.8752 117.84 45.8688C117.749 45.847 117.655 45.8351 117.56 45.8224C117.461 45.8091 117.363 45.7957 117.264 45.7929C117.238 45.7922 117.213 45.7852 117.186 45.7852H58.1491C56.5959 45.7852 55.3366 47.0445 55.3366 48.5977C55.3366 50.1509 56.5959 51.4102 58.1491 51.4102H113.512L106.347 77.8589H61.3174C56.7788 77.8589 53.0859 81.551 53.0859 86.0897C53.0859 90.6284 56.7788 94.3212 61.3174 94.3212H117.188C118.741 94.3212 120 93.0619 120 91.5087C120 89.9555 118.741 88.6962 117.188 88.6962Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.5234 102.758C56.8709 102.758 53.0859 106.543 53.0859 111.195C53.0859 115.848 56.8709 119.633 61.5234 119.633C66.176 119.633 69.9609 115.848 69.9609 111.195C69.9609 106.543 66.176 102.758 61.5234 102.758ZM61.5234 114.008C59.973 114.008 58.7109 112.746 58.7109 111.195C58.7109 109.644 59.973 108.383 61.5234 108.383C63.0738 108.383 64.3359 109.644 64.3359 111.195C64.3359 112.746 63.0738 114.008 61.5234 114.008Z",
          fill: "#E7775A",
        },
      }),
      _c("path", {
        attrs: {
          d: "M99.9746 102.758C95.322 102.758 91.5371 106.543 91.5371 111.195C91.5371 115.848 95.322 119.633 99.9746 119.633C104.627 119.633 108.412 115.848 108.412 111.195C108.412 106.543 104.627 102.758 99.9746 102.758ZM99.9746 114.008C98.4242 114.008 97.1621 112.746 97.1621 111.195C97.1621 109.644 98.4242 108.383 99.9746 108.383C101.525 108.383 102.787 109.644 102.787 111.195C102.787 112.746 101.525 114.008 99.9746 114.008Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }