var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "torna-indietro mt-4 mb-4 d-flex justify-content-end" },
    [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.mixinGoBack()
            },
          },
        },
        [_c("BtnBack", { attrs: { text: "Torna indietro" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }